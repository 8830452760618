import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

//<h3>Bryan Crawford, industry veterans of over 30 years</h3>

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Bar Feed Maintenance and Repair</h1>
    
    <StaticImage
            src="../images/Bryan-Crawford.jpg"
            alt="Bryan Crawford"
            width={'360'}
            opacity
            style={{
              float: 'right',
              margin: '12px'
            }}
          />


    <p>BCbarfeeds, LLC is Bryan Crawford. I have worked on and installed many brands and types of machines, but mostly bar feeders for over 35 years. </p>

    <p>When I started with SMW Systems (later acquired by ATS), the most common feeder was the single tube type of hydrodynamic barfeed. Then came the short loader that was pneumatically powered. The advantage of that way was since the bar is totally contained (no vibration) within the spindle and it can automatically load a bar, it became the prevalent way of doing fixed headstock bar feeding.</p>
    <p>With MTA, I helped CNC Enhancements come out with a servo powered short bar loader. Also while with MTA, we became the FMB 12 ft magazine bar feeder representatives in the Americas.</p>

    <p>I joined Hydromat's division of bar feeders, then selling and installing the Iemca brand of bar loaders. In the early 2000s we became Edge Technologies selling, installing and servicing the Edge brand of bar feeders and FMB.</p>

    <p>While never having actually worked for LNS, I do know most of the sales and service personnel and call them for help when working on a LNS bar loader.</p>
  </Layout>
)

export default IndexPage
